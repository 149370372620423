.wpo-service-area,
.wpo-service-s2,
.service-style-3 {
  padding: 100px 0 70px;
  background: #fafafa;
}

.wpo-section-title h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
  padding-bottom: 20px;
  margin-bottom: 60px;
  position: relative;
}
.flex {
  display: flex;
}
.wpo-section-title h2:before {
  content: "";
  position: absolute;
  left: 47%;
  bottom: 0;
  width: 60px;
  height: 3px;
}
.wpo-service-single {
  overflow: hidden;
}
.wpo-service-single {
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  margin-bottom: 30px;
}

.wpo-service-single:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(20, 36, 64, 0.2);
}

.wpo-service-area .wpo-service-item .wpo-service-single:before {
  content: none;
}
.wpo-service-s2 .wpo-service-item .wpo-service-single .wpo-service-icon {
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  margin: auto;
  margin-bottom: 25px;
}

.wpo-service-s2 .wpo-service-item .wpo-service-single .wpo-service-icon i,
.service-style-3 .wpo-service-item .wpo-service-single .wpo-service-icon i {
  color: #ffffff;
}
.wpo-service-s2 .wpo-service-item .wpo-service-single h2,
.service-style-3 .wpo-service-item .wpo-service-single h2 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}
.wpo-service-s2 .wpo-service-item .wpo-service-single p,
.service-style-3 .wpo-service-item .wpo-service-single p {
  line-height: 30px;
  color: #5d5b5b;
  margin-bottom: 0;
}

.wpo-service-s2 .wpo-service-item .wpo-service-single {
  background: #fff;
  text-align: center;
  padding: 40px 33px;
}
.wpo-service-s2 .wpo-service-single:before {
  display: none;
}
