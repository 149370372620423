.App {
  /* -ms-overflow-style: ; */
  scrollbar-width: 7px;
}

.App::-webkit-scrollbar {
  width: 7px;
}

.App::-webkit-scrollbar-track {
  display: none;
}

.App::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 4px;
}
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbcaca;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #919090;
}

/* 
        scrollbarWidth: "none",
        "&::-webkit-scrollbar" :{
            width: "7px"
        },
        "&::-webkit-scrollbar-track" : {
            display: "none"
        },
         
        "&::-webkit-scrollbar-thumb" : {
          background: "#dadada",
          borderRadius: "4px"

        },
 */

/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name:     Shifter – Transport and Logistics React Template
Version:        1.0.0
Author:         wpoceans
URL:            https://themeforest.net/user/wpoceans
-------------------------------------------------------------------*/

/*---------------------------
  Fonts
----------------------------*/
/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/

body {
  font-family: "Cairo", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  color: #333;
  font-size: 0.78125rem !important;
}

p {
  line-height: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cairo", sans-serif;
  color: #353535;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0 !important;
  margin-bottom: 0;
}

a {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

img {
  max-width: 100%;
}

button,
a:focus {
  outline: none;
}

.features-padding {
  padding: 100px 0;
}

@media (max-width: 991px) {
  .features-padding {
    padding: 90px 0;
  }
}

@media (max-width: 767px) {
  .features-padding {
    padding: 80px 0;
  }
}

.features-padding {
  padding: 100px 0;
}

.section-padding {
  padding: 100px 0;
}

.ptb-100-70 {
  padding: 100px 0 70px;
}

.fixed-navbar {
  position: relative;
  z-index: 5555;
}

.fixed-navbar.active .header-style-1,
.fixed-navbar.active .header-style-2,
.fixed-navbar.active .header-style-3 {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  border-bottom: 2px solid #c4c4c4;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
}

.fixed-navbar.active .header-style-2,
.fixed-navbar.active .header-style-3 {
  background: #231b0e;
}

@media print {
  .MuiDrawer-root {
    display: none !important;
  }

  .LayoutWithDrawer-contentShift {
    width: auto !important;
  }

  /* .Shipments-mainSearch {
    width: 100% !important;
  } */
}
